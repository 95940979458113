import Head from 'next/head'
import { Container } from '@doist/marketist'
import path from 'path'
import matter from 'gray-matter'
import fs from 'fs'
import { postFilePaths, POSTS_PATH } from 'utils/mdxUtils'
import { GetStaticPropsResult } from 'next'

import * as Sections from 'components/home'
import { normalizeAuthors } from 'services/authorNormalizer'

interface IndexProps {
    posts: Post[]
}

export default function Index({ posts }: IndexProps): JSX.Element {
    const pageTitle = 'Doist Engineering'
    const pageDescription = 'Technical content authored by engineers, for engineers.'
    return (
        <>
            <Head>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={pageDescription} />
                <meta property="og:image" content="https://doist.dev/og-image.png" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:description" content={pageDescription} />
                <meta name="twitter:image" content="https://doist.dev/og-image.png" />
                <link rel="icon" sizes="160x160" type="image/svg" href="/favicon.svg" />
                <link rel="mask-icon" href="/favicon-mask.png" color="#121212" />
                <link
                    rel="alternate"
                    type="application/rss+xml"
                    title={`${pageTitle} RSS Feed`}
                    href="/rss.xml"
                />
            </Head>

            <main style={{ minHeight: 'calc(100vh - 310px)' }}>
                <Container heroSection>
                    <Sections.Heading />
                    <Sections.PostCardGrid posts={posts} />
                </Container>
            </main>
        </>
    )
}

export function getStaticProps(): GetStaticPropsResult<IndexProps> {
    const posts = postFilePaths
        .map((filePath) => {
            const source = fs.readFileSync(path.join(POSTS_PATH, filePath))
            const { content, data } = matter(source)

            return {
                content,
                data: {
                    ...data,
                    authors: normalizeAuthors(data.authors),
                },
                filePath,
            } as Post
        })
        // sort newest to oldest.
        .sort((a, b) => new Date(b.data.pubDate).getTime() - new Date(a.data.pubDate).getTime())

    return { props: { posts } }
}
