import * as React from 'react'
import { Box, SearchField } from '@doist/marketist'
import { PostCard } from './PostCard/PostCard'
import { searchPosts } from 'services/search'

import css from './PostCardGrid.module.css'

type PostCardGridProps = {
    posts: Post[]
}

export function PostCardGrid({ posts }: PostCardGridProps): JSX.Element {
    const [searchResults, setSearchResults] = React.useState<Post[]>(posts)

    function onSearch(event: React.FormEvent<HTMLInputElement>) {
        const searchText = event.currentTarget.value.trim()
        if (searchText.length === 0) {
            setSearchResults(posts)
        } else {
            const matches = searchPosts(searchText, posts)
            setSearchResults(matches)
        }
    }

    return (
        <Box className={css.postCardGrid}>
            <div className={css.searchBar}>
                <SearchField
                    placeholder="Search"
                    onChangeSearch={onSearch}
                    searchLabel="Search"
                    clearLabel="Clear search"
                />
            </div>

            <ul className={css.grid}>
                {searchResults.map((post) => (
                    <li key={post.filePath}>
                        <PostCard post={post} />
                    </li>
                ))}
            </ul>
        </Box>
    )
}
