import { Heading, HyperLink, Text } from '@doist/marketist'
import { formatPubDate } from 'services/dateFormatter'

import slugify from 'slugify'

import css from './PostCard.module.css'

type TileProps = {
    post: Post
}

export function PostCard({ post }: TileProps): JSX.Element {
    const titleId = 'post-' + slugify(post.data.title)
    return (
        <HyperLink
            aria-describedby={titleId}
            className={css.postCard}
            href={`/posts/${post.filePath.replace(/\.mdx?$/, '')}`}
        >
            <Text size="minor-xs" className={css.date}>
                {formatPubDate(post.data.pubDate)}
            </Text>
            <Heading id={titleId} className={css.title} level="2" size="minor-lg">
                {post.data.title}
            </Heading>
            <Text size="body-sm" className={css.snippet}>
                {post.data.snippet}
            </Text>
        </HyperLink>
    )
}
