import { Box, Heading as MarketistHeading, LazyImage } from '@doist/marketist'
import css from './Heading.module.css'

import illustration from './dev-blog-illustration@2x.jpg'

export function Heading(): JSX.Element {
    return (
        <Box className={css.heading}>
            <MarketistHeading className={css.title} level="1" size="major-lg">
                Technical content authored by engineers, for engineers.
            </MarketistHeading>

            <LazyImage className={css.illustration} src={illustration} width="312" height="171" />
        </Box>
    )
}
